/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import classnames from 'classnames';
import {Navbar, Footer} from '@stjudecloud/ui-react';
import Seo from './seo';

const LoadableAlert = Loadable({
  loader: () => import('./announcement-alert'),
  loading() {
    return '';
  },
});

const Layout = ({className, children}) => (
  <div className="layout-alert-container">
    <Seo />
    <div>
      <LoadableAlert />
    </div>
    <div className={classnames('content-layout-container', className)}>
      <Navbar portalConfig={{title: '', link: ''}} />
      <div className="content">{children}</div>
      <Footer />
    </div>
  </div>
);

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
